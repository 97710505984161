import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import './App.css';

function App() {

  const SOCIAL_ICON_TYPE = {
    FACEBOOK: 'FACEBOOK',
    INSTAGRAM: 'INSTAGRAM',
    LINKEDIN: 'LINKEDIN',
    TWITTER: 'TWITTER'
  }

  const mailToSupportClickHandler = () => {
    window.location = "mailto:info@ecreate.buzz"
  }

  const handleSocialMediaClick = (type) => {
    if (type === SOCIAL_ICON_TYPE.FACEBOOK) {
      window.open('https://www.facebook.com/profile.php?id=61553689885739', '_blank')
    } else if (type === SOCIAL_ICON_TYPE.TWITTER) {
      window.open('https://twitter.com/WecreateBuzz', '_blank')
    } else if (type === SOCIAL_ICON_TYPE.LINKEDIN) {
      window.open('https://www.linkedin.com/company/wecreate-buzz/', '_blank')
    } else if (type === SOCIAL_ICON_TYPE.INSTAGRAM) {
      window.open('https://www.instagram.com/wecreate.buzz/', '_blank')
    }
  }

  return (
    <div className='body-container'>
      <nav className='nav-container'>
        <div className='logo-container'>
          <img src={require('./assets/logo.png')} className='img' />
        </div>
        <div className='nav-item-row-container'>
          {/* <a className='nav-item' href='#pricingSection'>PRICING</a> */}
          <div className='nav-item' onClick={() => { mailToSupportClickHandler() }}>CONTACT US</div>
        </div>
      </nav>
      <div className='service-row-container'>
        <div className='service-row-item-container'>
          <div className='service-icon-container'>
            <img src={require('./assets/ic_article.png')} className='img' />
          </div>
          <div>
            <div className='service-icon-label'>ecreate Buzz</div>
            <div className='service-icon-sub-label'>The potency of persuasion</div>
          </div>
        </div>
        <div className='service-row-item-container'>
          <div className='service-icon-container'>
            <img src={require('./assets/ic_buzz.png')} className='img' />
          </div>
          <div>
            <div className='service-icon-label'>ecreate SEO</div>
            <div className='service-icon-sub-label'>We get you ranking</div>
          </div>
        </div>
        <div className='service-row-item-container'>
          <div className='service-icon-container'>
            <img src={require('./assets/ic_location.png')} className='img' />
          </div>
          <div>
            <div className='service-icon-label'>ecreate Places</div>
            <div className='service-icon-sub-label'>Be everywhere at once</div>
          </div>
        </div>
        <div className='service-row-item-container'>
          <div className='service-icon-container'>
            <img src={require('./assets/ic_feedback.png')} className='img' />
          </div>
          <div>
            <div className='service-icon-label'>ecreate Feedback</div>
            <div className='service-icon-sub-label'>The key to better reviews</div>
          </div>
        </div>
      </div>
      <section className='hero-section-container'>
        <div className='row'>
          <div className='col-sm-6 hero-text-container'>
            <h1 className='hero-heading'>Marketing Your Business Just Go Easier with Ecreate</h1>
            <h3 className='hero-sub-heading-one'>Our social media services are proven to drive appealing content and drive traffic for your business to the website.</h3>
            <p className='hero-sub-heading-one'>Attract. Interact. Capture. Create</p>
            <div className='hero-button-container' onClick={() => { mailToSupportClickHandler() }}>CONTACT US</div>
          </div>
          <div className='col-sm-6'>
            <img src={require('./assets/hero_ecreate.png')} className='img' />
          </div>
        </div>
      </section>
      <section className='section-offer'>
        <h2 className='offer-heading'>Experience unparalleled products that elevate your online business to new heights.</h2>
        <h4 className='offer-sub-heading'>Your business's most crucial asset is its website. With over 100 billion online searches every month, all the customers you could ever desire are just a click away. We've created potent products that, once integrated into your website, strive to propel your business to the next level.</h4>
        <div className='offer-row-container'>
          <div className='offer-row-item-container'>
            <div className='offer-img-container'>
              <img src={require('./assets/ic_article_white.png')} className='img' />
            </div>
            <div className='offer-img-label'>ecreate Buzz</div>
            <p className='offer-img-sub-label'>Adds premium articles into your website and social media channels, generating substantial visitor traffic to your site.</p>
          </div>
          <div className='offer-row-item-container'>
            <div className='offer-img-container'>
              <img src={require('./assets/ic_buzz_white.png')} className='img' />
            </div>
            <div className='offer-img-label'>ecreate SEO</div>
            <p className='offer-img-sub-label'>Immerse yourself in top-tier local data management that will place your business on the map and boost your rankings.</p>
          </div>
          <div className='offer-row-item-container'>
            <div className='offer-img-container'>
              <img src={require('./assets/ic_location_white.png')} className='img' />
            </div>
            <div className='offer-img-label'>ecreate Places</div>
            <p className='offer-img-sub-label'>Elevate your business to appear in search results beyond your local area, attracting increased visitor traffic.</p>
          </div>
          <div className='offer-row-item-container'>
            <div className='offer-img-container'>
              <img src={require('./assets/ic_feedback_white.png')} className='img' />
            </div>
            <div className='offer-img-label'>ecreate Feedback</div>
            <p className='offer-img-sub-label'>Minimize the risk of negative reviews by seeking your customer's thoughts beforehand and then inviting them to share their feedback.</p>
          </div>

        </div>
      </section>
      <section className='section-opportunity'>
        <div className='row'>
          <div className='col-sm-6 section-opportunity-img-container'>
            <img src={require('./assets/opportunity_one.png')} className='img' />
          </div>
          <div className='col-sm-6'>
            <div>
              <h2 className='section-opportunity-heading'>A chance that is too valuable to overlook!</h2>
              <p className='section-opportunity-sub-heading'>All your potential customers leverage online searches to fulfill their needs. Your website plays a pivotal role in converting these visitors into sales. Investing in and driving traffic to your website is the most effective strategy to enhance visibility and boost sales.</p>
              <h5 className='section-opportunity-heading-two'>Explore our offerings below:</h5>
              <p className='section-opportunity-sub-heading-one'>&#9679; Blogging & Social Media Posting – <span className='section-opportunity-sub-heading-one-bold'>ecreate Buzz</span></p>
              <p className='section-opportunity-sub-heading-one'>&#9679; Localized Search Traffic – <span className='section-opportunity-sub-heading-one-bold'>ecreate SEO (Basic & Advanced)</span></p>
              <p className='section-opportunity-sub-heading-one'>&#9679; Achieve high rankings in search results beyond your geographical location – <span className='section-opportunity-sub-heading-one-bold'>ecreate Places</span></p>
              <p className='section-opportunity-sub-heading-one'>&#9679; Gather insights from customer feedback before seeking a review – <span className='section-opportunity-sub-heading-one-bold'>ecreate Feedback</span></p>
              <p className='section-opportunity-sub-heading-one'>&#9679; Own a meticulously designed website ready for effective ranking – <span className='section-opportunity-sub-heading-one-bold'>Website Development</span></p>
              <p className='section-opportunity-sub-heading-one'>&#9679; Necessitate consistent updates for your website – <span className='section-opportunity-sub-heading-one-bold'>Website Management</span></p>
              <p className='section-opportunity-sub-heading-one'>&#9679; Google and Facebook Ads management – <span className='section-opportunity-sub-heading-one-bold'>Online Advertising</span></p>
              <div className='nav-item' onClick={() => { mailToSupportClickHandler() }}>Contact Us Now</div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='section-pricing' id='pricingSection'>
        <h2 className='pricing-heading'>Our Packages</h2>
        <p className='pricing-sub-heading'>Choose from our affordable and customizable social media marketing packages</p>
        <div className='row pricing-row-container'>
          <div className='col-sm-3 pricing-row-item-container'>
            <h2 className='pricing-pack-label'>Basic</h2>
            <div className='pricing-pack-currency-container'>
              <span className='pricing-pack-currency-label'>Rs.</span>
              <div className='pricing-pack-currency-value'>30,000</div>
            </div>
            <div className='pricing-pack-duration-label'>per month</div>
            <div className='pricing-pack-services'>&#9679; 3 Posts a week</div>
            <div className='pricing-pack-services'>&#9679; 1 Social Media Platform</div>
            <div className='pricing-pack-services'>&#9679; 1 Social Media Content Calendar (GIFs not included)</div>
            <div className='pricing-pack-services'>&#9679; Business Page Creation</div>
            <div className='pricing-pack-services'>&#9679; Page Management</div>
            <div className='pricing-pack-services'>&#9679; Paid Boosting (not included in the package)</div>
          </div>
          <div className='col-sm-4 pricing-row-item-container-popular'>
            <h2 className='pricing-pack-label'>Standard</h2>
            <div className='pricing-pack-currency-container'>
              <span className='pricing-pack-currency-label'>Rs.</span>
              <div className='pricing-pack-currency-value'>35,000</div>
            </div>
            <div className='pricing-pack-duration-label'>per month</div>
            <div className='pricing-pack-services'>&#9679; 5 Posts a week</div>
            <div className='pricing-pack-services'>&#9679; 1 Social Media Platform</div>
            <div className='pricing-pack-services'>&#9679; 1 Social Media Content Calendar (GIFs not included)</div>
            <div className='pricing-pack-services'>&#9679; Business Page Creation</div>
            <div className='pricing-pack-services'>&#9679; Page Creation & Opitmization</div>
            <div className='pricing-pack-services'>&#9679; Monthly Report</div>
            <div className='pricing-pack-services'>&#9679; Paid Boosting (not included in the package)</div>
          </div>
          <div className='col-sm-3 pricing-row-item-container'>
            <h2 className='pricing-pack-label'>Advance</h2>
            <div className='pricing-pack-currency-container'>
              <span className='pricing-pack-currency-label'>Rs.</span>
              <div className='pricing-pack-currency-value'>40,000</div>
            </div>
            <div className='pricing-pack-duration-label'>per month</div>
            <div className='pricing-pack-services'>&#9679; 7 Posts a week</div>
            <div className='pricing-pack-services'>&#9679; 2 Social Media Platform</div>
            <div className='pricing-pack-services'>&#9679; 1 Social Media Content Calendar (GIFs not included)</div>
            <div className='pricing-pack-services'>&#9679; Business Page Creation</div>
            <div className='pricing-pack-services'>&#9679; Page Optimization</div>
            <div className='pricing-pack-services'>&#9679; Page Management</div>
            <div className='pricing-pack-services'>&#9679; Monthly Report</div>
            <div className='pricing-pack-services'>&#9679; Branding &amp; Awareness Campaigns</div>
            <div className='pricing-pack-services'>&#9679; Organic Boosting</div>
            <div className='pricing-pack-services'>&#9679; Paid Boosting (not included in the package)</div>
          </div>
        </div>
      </section> */}
      <div className='screenFooter'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 colElementItemCenter'>
              <div className='footer-logo-container'>
                <img src={require('./assets/logo.png')} className='img' />
              </div>
            </div>
            <div className='col-sm-4 colElement'>
            </div>
            <div className="col-sm-4 colElementCenter">
              <div className="colElementHeadingLogo">Contact</div>
              <div className="colElement14Label" onClick={() => { mailToSupportClickHandler() }}>info@ecreate.buzz</div>
              <div className='social-icon-row-container'>
                <div className='social-icon-container' onClick={() => { handleSocialMediaClick(SOCIAL_ICON_TYPE.TWITTER) }}>
                  <img src={require('./assets/ic_twitter.png')} className='img' alt='...' />
                </div>
                <div className='social-icon-container' onClick={() => { handleSocialMediaClick(SOCIAL_ICON_TYPE.INSTAGRAM) }}>
                  <img src={require('./assets/ic_instagram.png')} className='img' alt='...' />
                </div>
                <div className='social-icon-container' onClick={() => { handleSocialMediaClick(SOCIAL_ICON_TYPE.FACEBOOK) }}>
                  <img src={require('./assets/ic_facebook.png')} className='img' alt='...' />
                </div>
                <div className='social-icon-container' onClick={() => { handleSocialMediaClick(SOCIAL_ICON_TYPE.LINKEDIN) }}>
                  <img src={require('./assets/ic_linkedin.png')} className='img' alt='...' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
